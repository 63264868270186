<template>
  <v-form
    ref="form"
    on-submit="return false;"
    @submit="submit"
  >
    <v-container fluid>
      <v-validation-errors :validation-errors="validationErrors" />
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="userData.username"
            label="Username"
            :rules="rules.username"
            outlined
          />
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="userData.email"
            label="E-mail"
            :rules="rules.email"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-select
            v-model="userData.user_type_id"
            :items="roles"
            label="User Role"
            item-text="name"
            item-value="id"
            :rules="rules.role"
            outlined
          />
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-select
            v-model="userData.branch_id"
            :items="branches"
            label="Branch"
            item-text="name"
            item-value="id"
            outlined
            :rules="$auth.getPartnerType() === 'drug_delivery' ? rules.branch : []"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="userData.password"
            label="Password"
            type="password"
            autocomplete="new-password"
            outlined
            :rules="$route.name === 'Add user' ? rules.password: ''"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="text-right"
        >
          <v-btn
            color="success"
            class="mr-0"
            @click="submit"
          >
            Submit Form
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
  import VValidationErrors from "../../components/base/VValidationErrors";
  export default {
    components: { VValidationErrors },
    data: () => ({
      userData: {
        username: '',
        email: '',
        branch_id: '',
        password: '',
        user_type_id: ''
      },
      roles: [],
      validationErrors: {},
      branches: [],
      rules: {
        username: [
          v => !!v || 'Username is required',
        ],
        email: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        role: [
          v => !!v || 'User role is required',
        ],
        branch: [
          v => !!v || 'User branch is required',
        ],
        password: [
          v => !!v || 'User password is required',
        ],
      },
    }),
    async created () {
      this.getPartnerBranches();
      await this.getAvailableUserType()
    },
    methods: {
      getUserData () {
        this.$authApi.get(`/users/${this.$route.params.id}`).then(res => {
          // this.userData = res.data.data.username;
          this.userData = {
            username: res.data.data.username,
            email: res.data.data.email,
            branch_id: res.data.data.branch_id,
            user_type_id: res.data.data.user_type_id,
          }
        });
      },
      getPartnerBranches () {
        this.$authApi.get(`/branches`).then(res => {
          this.branches = res.data.data.data.map((element) => {
            return { id: element.id, name: element.name }
          });
          if (this.$route.name !== 'Add user') {
            this.getUserData();
          }
        })
      },
      submit () {
        if (!this.$refs.form.validate()) {
          return;
        }
        if (this.$route.name !== 'Add user') {
          this.updateUser();
        } else {
          this.addUser()
        }
      },
      updateUser () {
        this.$authApi.put(`/users/${this.$route.params.id}`, this.userData)
          .then(res => {
            this.flashMessage.success({
              title: 'User Edited Successfully',
            });
            this.$router.push('/users')
          }).catch(error => {
            if (error.response) {
              this.validationErrors = error.response.data.errors;
            }
          })
      },
      addUser () {
        this.$authApi.post('users', this.userData)
          .then(res => {
            this.flashMessage.success({
              title: 'User Added Successfully',
            });
            this.$router.push('/users')
          }).catch(error => {
            if (error.response) {
              this.validationErrors = error.response.data.errors;
            }
          })
      },
      async getAvailableUserType () {
        const { data: { data } } = await this.$authApi.get('user-types')
        this.roles = data
      }
    }
  }
</script>
